.Menu {
  // background-color: white;
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0.782) 15%,
  //   rgb(255, 255, 255) 30%,
  //   rgb(255, 255, 255)  60%,
  //   rgba(253, 250, 250, 0.782) 83%
  // );
  // background: rgb(255, 255, 255);
  // background: linear-gradient(
  //   90deg,
  //   rgba(233, 233, 233, 0.856) 20%,
  //   rgba(242, 242, 242, 1) 82%,
  //   rgb(255, 255, 255) 85%,
  //   rgba(197, 197, 197, 0.851) 100%
  // );
  // background-clip: text;
  // -webkit-background-clip: text;
  color: transparent;
}
/* .MenuCountainer{
    text-transform: uppercase;
    background: radial-gradient(
      circle farthest-corner at center center,
      white,
      #111
    ) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } */
/* .LiMEnu {
    margin-left: 5%;
    cursor: pointer;
  } */
.Body {
  width: 75vw;
  font-size: 18px;
  flex-grow: 100;
  color: #000000;
  top: 1rem;
  background-color: white;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
  scroll-padding-left: -15%;
  border-radius: 10px;
  .Texts {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    // width: 50vw;
  }
}
.Body::-webkit-scrollbar {
  width: 0.4em;
}
.Body::-webkit-scrollbar-thumb {
  background: #00c846;
  border-radius: 25px;
  padding-top: 2%;
}
.Body::-webkit-scrollbar-track {
  border-radius: 25px;
}
.Body::-webkit-scrollbar-button {
  background: rgba(177, 53, 53, 0);
  height: 18px;
}
.MenuCountainer {
  width: 80vw;
}
.IsActive {
  color: #000000 !important;
}

.SlideShow {
  transform: translate3d(-1775.19px, 0px, 0px);
  transition-duration: 0ms;
}
.Pragragh::-webkit-scrollbar-thumb {
  background-color: rgb(15, 15, 15);
  border-radius: 15px;
  outline: 1px solid slategrey;
}
.Head {
  padding-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: large;
}

.SubPragraph {
  margin-bottom: 10px;
}
.List {
  margin-bottom: 10px;
}
.NotActive {
  color: rgba(253, 253, 253, 0.699);
  background-clip: text;
  -webkit-background-clip: text;
}
.NotActive:hover {
  background-color: #a3c800;
  color: #00c846;
  // height: 10rem;
  // width: 15rem;
  // position: relative;
}
.Pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: -60%;
  color: white;
  background-color: white;
  width: 10vh;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
}
.NotActivePaginatio {
  color: #00c846;
  opacity: 0.5;
  font-size: small;
}
.PaginationItem {
  top: 40%;
  left: 33px;
  cursor: pointer;
}
.PaginationItemDwon {
  top: 51.5%;
  left: 33px;
  cursor: pointer;
}
.dispalyNone {
  display: none;
}

.Countainer {
  /* position: absolute; */
  width: 4%;
  height: 3%;
  text-align: center;
}
// .Countainer:hover{
//   position: relative;
//   background-color: #ffc814;
//   width: 4%;
//   height: 3%;
//   color: #00c846;
// }
.ActivePagination {
  color: #000000;
  font-size: larger;
  font-weight: 800;
}
.NotActiveLeft {
  color: white;
  /* text-transform: uppercase;
    background: radial-gradient(
      circle farthest-corner at center center,
      white,
      #111
    ) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.NotActiveRight {
  color: white;
}
.NotActivePagination {
  color: #000000;
  opacity: 0.6;
  font-size: large;
  font-weight: 600;
}
.menu {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  color: azure;
  position: absolute;
  font-weight: bold;
  width: 500px;
  right: 40%;
  /* Add other styling properties */
}

.menuItem {
  flex: 0 0 auto;
  padding: 10px 20px;
  scroll-snap-align: center;
  cursor: pointer;
  /* Add other styling properties */
}

.menuItem.selected {
  font-weight: bold;
  /* Add other styling properties for selected state */
}
.PaginationMobile {
  width: 100vw;
  height: 39px;
  background-color: #00c846;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28.5%;
  color: white;
}
.ActivePaginationMobile {
  color: white;
  text-align: center;
  font-weight: bold;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.NotActivePaginationMobile {
  color: white;
  text-align: center;
  opacity: 0.6;
}
.MenuMobile {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 10%;
  color: #00c846;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* background: linear-gradient(
      to right,
      rgb(252, 0, 0) 20%,
      rgba(183, 255, 0, 0.782) 50%,
      rgba(252, 252, 252, 0.782) 50%,
      rgb(0, 0, 0) 80%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent; */
  opacity: 0.8;
}
.ActiveMenuMobile {
  color: #00c846;
}
.NotActiveText {
  color: white;
  opacity: 0.2;
}
.PragraghMobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  color: white;
  width: 70vw;
  overflow: auto;
  max-height: 500px;
  margin-right: 4em;
  /* تعیین فاصله از راست */
  top: 30%;
  border-bottom: 15px solid black;
  border-top: 20px solid black;
  scroll-behavior: smooth;
}
