/* در فایل Style.module.css */
.Footer {
    background-color: black;
    z-index: 15;
    align-items: center;
    width: 130px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    right: 0;
    bottom: 0%;
    top: 0%;
    border-color: white;
    border-left: 2px solid rgb(255, 255, 255);
    animation-duration: 1s;
    animation-name: ShowFooter;
    animation-timing-function: ease;
  }
  
  .FooterAnimetion {
    background-color: black;
    align-items: center;
    width: 130px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    right: 0;
    bottom: 0;
    animation-duration: 1.2s;
    animation-name: showfooter;
    animation-timing-function: linear;
  }

  .BackFooterAnimetion{
    background-color: black;
    align-items: center;
    width: 6%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    opacity: 0;
    right: 0%;
    bottom: 0px;
    animation-duration: 1.2s;
    animation-name: Backfooter;
    animation-timing-function: linear;
  }
  .FooterWitoutAnime{
    background-color: black;
    z-index: 15;
    align-items: center;
    width: 130px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    right: 0;
    bottom: 0%;
    top: 0%;
    border-color: white;
    border-left: 2px solid rgb(255, 255, 255);
  }
  .BorderFooter {
    position: fixed;
    right: 6%;
    width: 2px;
    background-color: rgb(255, 255, 255);
    height: 100%;
    border-left: 2px solid rgb(255, 255, 255);
    animation-duration: 2s;
    animation-name: ShowBorder;
    animation-timing-function: ease;
  }
  .BackBorderFooter{
    position: fixed;
    right: 6%;
    width: 2px;
    background-color: rgb(255, 255, 255);
    height: 0%;
    
    border-left: 2px solid rgb(255, 255, 255);
    animation-duration: 2s;
    animation-name: BackBorder;
    animation-timing-function: ease;
  }
  .Menu {
    direction: rtl;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    
  }
  .Menu>li:hover{
    cursor: pointer;
    color: #00c846;
  }
  .MenuAnimtion>li{
    padding: 5px;
    font-size: 17px;
    line-height: 1.7;
  }
  .Logo {
    margin-top: 78%;
    cursor: pointer;
  }
  .LogoAnimation{
    margin-top: 78%;
    animation: LogoAnimation 3s linear;
  }
  .NotShowFooter {
    background-color: black;
    align-items: center;
    width: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    right: -5%;
    bottom: 0px;
  
    border-color: white;
    border-left: 2px solid rgb(255, 255, 255);
  }
  .MenuAnimtion {
    position: relative;
    direction: rtl;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40%;
    // padding-bottom: 25%;
    /* animation-duration: 4s;
    animation-name: ShowMenu;
    animation-timing-function: linear; */
  }
  .MenuAnimtion > li:hover {
    cursor: pointer;
    color: #00c846;
  }
  .AboutUs {
    // position: fixed;
    bottom: 125px;
    opacity: 1;
   
  }
  
  
  .Order {
    // position: fixed;
    bottom:68px;
    opacity: 1;
  }
  
  .Jobs {
    // position: fixed;
    bottom: 10px;
    opacity: 1;
  }
  
  .Active{
    color: #00c846;
    font-weight: bold;
  }
  .BackFooter{
    background-color: black;
    z-index: 15;
    align-items: center;
    width: 130px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    right: -15%;
    bottom: 0%;
    top: 0%;
    border-color: white;
    border-left: 2px solid rgb(255, 255, 255);
    animation-duration: 1s;
    animation-name: NotShowFooter;
    animation-timing-function: ease;
  }
  @keyframes ShowFooter{
    0%{
      right: -15%;
      opacity: 0;
    }
    20%{
      right:-15%;
      opacity: 0;
    }
    100%{
      right: 0%;
    }
  } 
  @keyframes NotShowFooter{
    0%{
      right: 0%;
      opacity: 1;
    }
    20%{
      right:0;
      opacity: 1;
    }
    100%{
      right: -15%;
    }
  }