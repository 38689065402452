.track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 100s linear infinite;
  }
  
  @keyframes marquee {
    from { transform: translateX(90%); }
    to { transform: translateX(-20%); }
  }
  .AnimeShow{
    animation: ShowMenu 1s cubic-bezier(0,.24,.42,.99) ;
  }
  .MenuFrist1 {
    opacity: 1;
    animation-duration: 1s;
    animation-name: menufrist1;
    animation-timing-function: ease;
  }
  .MenuFrist2 {
    opacity: 1;
    animation-duration: 1s;
    animation-name: menufrist2;
    animation-timing-function: ease;
  }
  .MenuFrist3 {
    opacity: 1;
    animation-duration: 1s;
    animation-name: menufrist3;
    animation-timing-function: ease;
  }
  .MenuFrist4 {
    opacity: 1;
    animation-duration: 1s;
    animation-name: menufrist4;
    animation-timing-function: ease;
  }
  .LogoChinesh{
    opacity: 1;
    animation-duration: 1s;
    animation-name: logoChinesh;
    animation-timing-function: ease;
  }
  @keyframes ShowMenu {
    0%{
      height:5rem;
      top: 9rem;
    }
  //  30%{
  //     height:5rem;
  //     top: 9rem;
  //   }
    50%{
      height:100vh ;
    }
    100%{
      height:100vh ;
    }
  }
  @keyframes logoChinesh {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }
  @keyframes menufrist1 {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }
  @keyframes menufrist2 {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }
  @keyframes menufrist3 {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }
  @keyframes menufrist4 {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }