.IsActive {
    color: #00c846;
    position: absolute;
    left: 25%;
  }
  .SetItem {
    left: 18% !important;
  }
  .NavBar {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 70%;
    overflow: auto;
    max-height: 500px;
    right: 10%;
    left: 15%;
  }
  .Body{
    width: 75vw;
    font-size: 18px;
    flex-grow: 100;
    color: #000000;
    top: 1rem;
    background-color: white;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    scroll-padding-left: -15%;
    border-radius: 10px;
  }
  .Body::-webkit-scrollbar {
    width: 0.4em;
    // position: absolute;
    // left: 5%;
    // background-color: #00c846;
  }
  .Body::-webkit-scrollbar-thumb {
    background: #00c846;
    border-radius: 25px;
    padding-top: 2%;
  }
  .Body::-webkit-scrollbar-track {
    // background: rgb(177, 53, 53);
    border-radius: 25px;
  }
  .Body::-webkit-scrollbar-button {
    background: rgba(177, 53, 53, 0);
    // border-radius: 25px;
    height: 18px;
  }
  .Pragragh {
    width: 70vw;
    color: white;
    padding-top: 4rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 8%;
    overflow: auto;
    margin-bottom: 10%;
  }
  .LiMenuSpit {
    margin-left: 17%;
    margin-right: 3%;
  }
  .Menu {
    // background: linear-gradient(
    //   90deg,
    //   rgba(255, 255, 255, 0.782) 15%,
    //   rgb(255, 255, 255) 30%,
    //   rgb(255, 255, 255)  60%,
    //   rgba(253, 250, 250, 0.782) 83%
    // );
  }
  
  .Menu li {
    /* margin: 0 8px; */
    cursor: pointer;
  }
  
  .NotActiveMenu {
    // color: rgba(243, 243, 242, 0.595);
    /* background-clip: text;
  -webkit-background-clip: text; */
  }
  .CloserActive {
    color: red;
  }
  .Active {
    color: #00c846;
    font-size: large;
    font-weight: 600;
    // background-color: rgb(255, 255, 255);
    z-index: 10;
    /* width: 337px; */
    /* text-align: center; */
  }
  .ActiveContainer {
    width: 400px;
    height: 47px;
    background-color: rgb(7, 7, 7);
    margin-left: auto;
    position: fixed;
  }
  .Pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: -60%;
    color: white;
    background-color: white;
    width: 10vh;
    margin-left: auto;
    margin-right: auto;
    height: 80%;
  }
  
  .Pagination .PaginationItem {
    margin: 0 8px;
    color: #00c846b1;
    cursor: pointer;
  }
  .PaginationItemActive {
    color: #00c846 !important;
    font-weight: bold;
    border-top: 2px solid #00c846;
    border-bottom: 2px solid #00c846;
  }
  .ContainerLeft {
    position: absolute;
    left: 0;
    width: 550px;
    height: 149px;
    background: linear-gradient(
      to right,
      rgb(0, 0, 0) 20%,
      rgba(0, 0, 0, 0.782) 50%,
      rgb(0, 0, 0) 80%,
      rgba(0, 0, 0, 0.495) 40%
    );
  }
  .ContainerRight {
    position: absolute;
    right: 0;
    width: 550px;
    height: 149px;
    background: linear-gradient(
      to left,
      rgb(0, 0, 0) 20%,
      rgba(0, 0, 0, 0.782) 50%,
      rgb(0, 0, 0) 80%,
      rgba(0, 0, 0, 0.495) 40%
    );
  }
  .ActivePagination {
    color: #00c846;
    /* background-color: #00c846; */
    // width: 100%;
    // height: 100%;
    font-size: larger;
    font-weight: 400;
    border: 2px #00c846;
    
    /* border-bottom: 2px #00c846 solid;
    border-top: 2px #00c846 solid; */
  }
  .NotActivePagination {
    color: #00c846;
    opacity: 0.6;
    font-size: larger;
    font-weight: 800;
  }
  .dispalyNone {
    display: none;
  }
  
  .ContainerBlack {
    position: fixed;
    background: linear-gradient(
      to bottom,
      rgb(0, 0, 0) 20%,
      rgb(0, 0, 0) 50%,
      rgba(0, 0, 0, 0.782) 50%,
      rgba(0, 0, 0, 0.578) 100%
    );
    width: 38%;
    height: 30px;
    top: 25%;
  }
  .ContainerBlackBottom {
    position: fixed;
    background: linear-gradient(
      to top,
      rgb(0, 0, 0) 20%,
      rgb(0, 0, 0) 50%,
      rgb(0, 0, 0) 80%,
      rgba(0, 0, 0, 0.756) 30%
    );
    width: 38%;
    height: 30px;
    bottom: 12%;
  }
  .Opacity_2 {
    opacity: 0.3;
  }
  //استایل های مورد نیاز برای منوی اپل 
  .chapternav {
    background: #f6f7f6;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    text-align: center;
    width:61vw;
    max-width: 61vw;
    margin-right: auto;
    margin-left: auto;
    /* height: 40px; */
    z-index: 1;
  }
  
  .chapternavItems::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    }
  .chapternavWrapper {
    position: relative;
    /* height: 100%; */
    z-index: 1;
  }
  
  .chapternavItems {
    list-style: none;
    margin: 0px 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .chapternav-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 0;
    padding: 5px 20px;
    cursor: pointer;
  }
  /* .chapternav-item:hover {
    background-color: #dadada;
  } */
  
  .chapternav-item:first-child {
    margin-left: 0px;
    padding-left: 4px;
    padding-right: 80px;
  }
  
  .chapternav-item:last-child {
    margin-right: 0;
    padding-right: 4px;
  }
  
  .chapternav-icon {
    display: block;
    margin: 0 auto 6px;
    height: 36px;
  }
  
  .chapternav-label {
    font-size: 18px;
    line-height: 1.09091;
    font-weight: 700;
    letter-spacing: -0.008em;
    display: block;
    margin: 0 auto;
  }
  .chapternav-label-active{
    font-size: 20px;
    line-height: 1.09091;
    font-weight: 700;
    letter-spacing: -0.008em;
    display: block;
    margin: 0 auto;
  }
  /* .chapternav-link {
    color: #111;
    display: block;
    margin-top: 3px;
    padding: 0;
    position: relative;
    z-index: 1;
    text-decoration: none;
  } */
  
  .chapternav-link.current {
    color: #48b;
    cursor: default;
    opacity: 0.6;
  }
  
  /* .chapternav-link:hover {
    color: #48b;
    cursor: pointer;
    opacity: 0.6;
  } */
  
  @-webkit-keyframes chapternav-slidein {
    0% {
      opacity: 0;
    }
  
    1% {
      -webkit-transform: translateX(160px);
      transform: translateX(160px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  @keyframes chapternav-slidein {
    0% {
      opacity: 0;
    }
  
    1% {
      -webkit-transform: translateX(160px);
      transform: translateX(160px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  .chapternav-item {
    -webkit-animation: chapternav-slidein 350ms backwards;
    animation: chapternav-slidein 350ms backwards;
  }
  
  .chapternav-paddle {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    border: 0 solid rgba(17, 17, 17, 0.2);
    border-radius: 0;
    color: #000;
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    width: 33px;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
  }
  
  .chapternav-paddle:after {
    opacity: 0.8;
    -webkit-transition: opacity 200ms linear;
    transition: opacity 200ms linear;
  }
  
  .chapternav-paddle:disabled {
    opacity: 0;
  }
  
  .chapternav-paddle:hover:after {
    opacity: 1;
  }
  
  .chapternav-paddle:focus {
    outline: none;
  }
  
  .chapternav-paddle:focus:after {
    outline: 3px solid #c1e0fe;
    outline: 3px solid rgba(131, 192, 253, 0.5);
    outline-offset: 4px;
    opacity: 1;
  }
  
  .chapternav-paddle:focus:after[data-focus-method='mouse']:not(input):not(textarea):not(select),
  .chapternav-paddle:focus:after[data-focus-method='touch']:not(input):not(textarea):not(select) {
    outline: none;
  }
  
  .chapternav-paddle:active {
    outline: none;
  }
  
  .chapternav-paddle:active:after {
    outline: none;
    opacity: 1;
  }
  
  .chapternav-paddle-left {
    border-right-width: 2px;
    left: 0;
  }
  
  .chapternav-paddle-left::before,
  .chapternav-paddle-left::after {
    color: inherit;
    display: inline-block;
    font-style: normal;
    font-weight: inherit;
    font-size: inherit;
    line-height: 1;
    position: relative;
    z-index: 1;
    alt: '';
  }
  
  .chapternav-paddle-left::before,
  .chapternav-paddle-left::after {
    text-decoration: none;
  }
  
  .chapternav-paddle-left::before {
    display: none;
  }
  
  /* .chapternav-paddle-left::before,
  .chapternav-paddle-left::after {
    content: '<';
  } */
  
  .chapternav-paddle-left:after {
    margin-left: 8px;
  }
  
  .chapternav-paddle-right {
    border-left-width:2px;
    right: 0;
  }
  
  .chapternav-paddle-right::before,
  .chapternav-paddle-right::after {
    color: inherit;
    display: inline-block;
    font-style: normal;
    font-weight: inherit;
    font-size: inherit;
    line-height: 1;
    position: relative;
    z-index: 1;
    alt: '';
  }
  
  .chapternav-paddle-right::before,
  .chapternav-paddle-right::after {
    text-decoration: none;
  }
  
  .chapternav-paddle-right::before {
    display: none;
  }
  
  /* .chapternav-paddle-right::before,
  .chapternav-paddle-right::after {
    content: '>';
  } */
  
  .chapternav-paddle-right:after {
    margin-right: 8px;
  }
  
  /* Main styles */
  #app {
    padding: 10px;
  }
  
  #head {
    margin-bottom: 25px;
  }
  