.verticalListLi {
  transform: rotate(270deg);
  font-weight: 500;
  font-size: 1.13rem;
  // display: flex;
  margin: 0 5rem 0 5rem;
  cursor: pointer;
}
.verticalListLi:hover {
  color: #ffffff;
}

.VerticalList>li{
  /* position: relative; */
  margin-top: 65px;
  transform: rotate(270deg);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  line-height: 1.7;
}
.Logo {
  cursor: pointer;
  /* text-align: center; */
  transform: rotate(270deg);
  padding: 10%;
  /* font-weight: bold; */
  /* margin-bottom: 86px; */
  // margin-top: 18vh;
  margin-bottom: 55px;
  margin-top: 15%;
  /* position: relative; */
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowLogoChinesh;
  animation-timing-function: ease;
  overflow-y: hidden;
  
} 
.LogoWithOutAnime{
  cursor: pointer;
  /* text-align: center; */
  transform: rotate(270deg);
  padding: 10%;
  /* font-weight: bold; */
  /* margin-bottom: 86px; */
  // margin-top: 18vh;
  margin-bottom: 55px;
  margin-top: 15%;
  /* position: relative; */
  overflow-y: hidden;
}
.ShowHeader {
  position: fixed;
  // position: relative;
  background-color: #00c846;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 8rem;
  height: 100vh;
  bottom: 0%;
  // top: 0%;
  margin-left:50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 50;
  visibility: visible;
  animation-duration: 2s;
  animation-name: HeaderActive;
  animation-timing-function: cubic-bezier(.4,.43,.16,.98);
  overflow: hidden;

}
.ShowHeader::-webkit-scrollbar {
  width: 0em;
  // position: absolute;
  // left: 5%;
  // background-color: #00c846;
}

.ShowHeaderWithoutAnime{
  position: fixed;
  // position: relative;
  background-color: #00c846;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 8rem;
  height: 100vh;
  bottom: 0%;
  // top: 0%;
  margin-left:50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 50;
  visibility: visible;
}
.NotShow {
  position: absolute;
  z-index: -15;
}
.NotShowLogo{
  display: none;
}
.ActiveMneu {

  color: #ffffff;
  border-top: 3px solid rgb(244, 242, 242);
  border-bottom: 3px solid rgb(244, 242, 242);
}
.Monogram {
  /* position: relative; */
  /* right: -3px; */
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-top:20px;
  cursor: pointer;
  /* width: 10px; */
  /* height: 110px; */
}
.FaidIn{
  overflow: hidden;
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowMenu;
  animation-timing-function: ease;
  overflow-y: hidden;
  
}
.FaidOut{
  overflow: hidden;
  opacity: 0;
  animation-duration: 1s;
  animation-name: NotShowMenu;
  animation-timing-function: ease;
  overflow-y: hidden;
}
.NotShowHeader{
  position: fixed;
  // position: relative;
  background-color: #00c846;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 8rem;
  height: 0vh;
  bottom: 0vh;
  // top: 0%;
  margin-left:50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 50;
  visibility: visible;
  animation-duration: 1s;
  animation-name: HeaderNotActive;
  animation-timing-function: ease;
  overflow: hidden;
}
@keyframes NotMid {
  from {
    bottom: 0%;
  }

  to {
    bottom: -5%;
  }
}
.line-1 {
  height: 1px;
  background: black;
}


///NEW ANIMATION
.AnimeLogo{
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowLogo;
  animation-timing-function: ease;
  overflow-y: hidden;
} 
.AnimeLogoWithOutAnime{
  opacity: 1;
  overflow-y: hidden;
} 
.AnimeMenuOne{
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowMenuOne;
  animation-timing-function: ease;
  overflow-y: hidden;
}
.AnimeMenuOneWithOutAnime{
  opacity: 1;
  overflow-y: hidden;
}
.AnimeMenuTwo{
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowMenuTwo;
  animation-timing-function: ease;
  overflow-y: hidden;
}
.AnimeMenuTwoWithOutAnime{
  opacity: 1;

  overflow-y: hidden;
}
.AnimeMenuThree{
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowMenuThree;
  animation-timing-function: ease;
  overflow-y: hidden;
}
.AnimeMenuThreeWithOutAnime{
  opacity: 1;

  overflow-y: hidden;
}
.AnimeMenuFour{
  opacity: 1;
  animation-duration: 1s;
  animation-name: ShowMenuFour;
  animation-timing-function: ease;
  overflow-y: hidden;
}
.AnimeMenuFourWithOutAnime{
  opacity: 1;
  overflow-y: hidden;
}
@keyframes HeaderActive {
  0% {
    height: 36vh;
  }
  // 10% {
  //   height: 36vh;
  // }
  /* 30%{
    height: 64%;
  } */
  30% {
    height: 100vh;
  }
  /* 100%{
    height: 100%;
  } */
}

@keyframes ShowLogo{
  0%{
    opacity: 0;
    transform: translateY(-70px);
  }
  10%{
    opacity: 0;
    transform: translateY(-70px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);

  }
}
@keyframes ShowMenuOne{
  0%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
 15%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  100%{
    opacity: 1;
    transform: translateY(0px) rotate(270deg);
  }
}
@keyframes ShowMenuTwo{
  0%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
 20%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  100%{
    opacity: 1;
    transform: translateY(0px) rotate(270deg);
  }
}
@keyframes ShowMenuThree{
  0%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  25%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg); 
  }
  100%{
    opacity: 1;
    transform: translateY(0px) rotate(270deg);
  }
}
@keyframes ShowMenuFour{
  0%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  30%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  100%{
    opacity: 1;
    transform: translateY(0px) rotate(270deg);
  }
} 
@keyframes ShowLogoChinesh{
  0%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  35%{
    opacity: 0;
    transform: translateY(-70px) rotate(270deg);
  }
  100%{
    opacity: 1;
    transform: translateY(0px) rotate(270deg);
  }
}
@keyframes HeaderNotActive{
  0%{
    height: 100vh;
  }
  40%{
    height: 100vh;
  }
  100%{
    height: 38vh;
  }
}

@keyframes NotShowMenu{
  10%{
    opacity: 1;
  }
  40%{
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}