.BlackContainer {
  position: fixed;
  direction: rtl;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  background-color: black;
  margin-right: 0;
  top: 0;
  display: flex;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
  animation-duration: 1s;
  animation-name: ShowContainer;
  animation-timing-function: cubic-bezier(.2,.04,.21,1);
}
.BackBlackContainer {
  opacity: 0;
  position: fixed;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  background-color: black;
  right: 0;
  top: 0;
  display: none;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
  animation-duration: 1s;
  animation-name: NotShowContainer;
  animation-timing-function: ease;
}
.BlackContainerWitoutAnime{
    position: fixed;
    direction: rtl;
    width: 50vw; /* نصف عرض صفحه */
    height: 100vh;
    background-color: black;
    margin-right: 0;
    top: 0;
    display: flex;
    justify-content: center; /* ترتیب افقی در وسط */
    align-items: center; /* ترتیب عمودی در وسط */
}
.BlackContainerMobile {
  position: absolute;
  width: 100vw; /* نصف عرض صفحه */
  // height: 100vh;
  top: 9rem;
  background-color: black;
  right: 0;
  bottom: 0;
}
.animate {
  position: relative;
  color: white;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: start;
  /* font-weight: 400; */
  font-size: 21px;
  padding-right: 80px;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.BackParagraph{
  position: relative;
  color: white;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: start;
  font-size: 21px;
  padding-right: 80px;
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.animateWitoutAnime{
  position: relative;
  color: white;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: start;
  padding-right: 80px;
}
.ParagraphMobile {
  color: white;
  // width: 95%;
  // height: 100vh;
  padding-left: 1rem ;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  // padding: 20px;
}
.Hiden {
  display: none;
  opacity: 0;
  animation-duration: 2s;
  animation-name: ShowContainer;
  animation-timing-function: ease;
}
.ShowOne {
  opacity: 1;
  animation-duration: 3.5s;
  animation-name: showone;
  animation-timing-function: ease;
}
.NotAnimeShowOne {
  opacity: 1;
}
.ShowTwo {
  opacity: 1;
  animation-duration: 4s;
  animation-name: showtwo;
  animation-timing-function: ease;
}
.ShowThree {
  opacity: 1;
  animation-duration: 4.6s;
  animation-name: showthree;
  animation-timing-function: ease;
  /* animation-delay: 2s; */
}
.NotAnimeShowTwo {
  opacity: 1;
}
.NotAnime {
  position: absolute;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  background-color: black;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
}
.NotAnimeShowThree {
  opacity: 1;
}
.DetailsCustomer {
  overflow-y: auto;
  font-size: 21px;
  color: white;
  position: relative;
  top: 0.5rem;
}
@keyframes container {
  from {
    width: 0;
    height: 0;
    right: 0;
    top: 0;
  }

  to {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
  }
}
@keyframes showone {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showtwo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  /* 98%{
    opacity: 1;
   } */
  100% {
    opacity: 1;
  }
}
@keyframes showthree {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  /* 98%{
    opacity: 0;
   } */
  100% {
    opacity: 1;
  }
}
@keyframes ShowContainer {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes NotShowContainer {
  0% {
    opacity: 1;
    display: flex;
  }
  60% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
/* انیمیشن نمایش خط به خط */
@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: translateY(+70px);
  }
  25%{
    opacity: 0;
    transform: translateY(+50px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0%{
    opacity: 1;
    transform: translateY(0px);
  }
  25%{
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    transform: translateY(+70);
    opacity: 0;
  }
}
/* @keyframes ShowContainer */
@media screen and (max-width: 1280px) {
  .paragraph {
    position: relative;
    color: white;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    /* margin-right: 150px; */
    /* padding: 40px; */
    padding-right: 80px;
  }
}
