
.SizeLogos {
//  width: 2rem; /* تغییر اندازه لوگو به دلخواه */
//  height: 8rem;
  height: auto;
  background-color: white;
}
.LogoMobile{
  overflow: hidden;
  // position: fixed;
  // top: 49%;
  // transform: translateY(-50%);
  margin-top: 50%;
  z-index: 0;
}
.animate{
  animation: movePathTwo 10s linear 0s infinite ;
}
@for $i from 1 through 55 {
    .path#{$i} {
      will-change: transform;
      transform: translateX(0px);
      position: absolute;
      top: 10%;
      left: 0;
      animation: movePath 50s linear 0s infinite;
    }
  /* تنظیم animation-delay برای هر کلاس به صورت داینامیک */
  .path#{$i} {
    animation-delay: #{$i}s;
  }
}
@keyframes movePath {
  0% {
    top: 2%; /* ابتدا در بالای صفحه */
    left: 0; /* ابتدا در چپ صفحه */
  }
  30% {
    top: 2%; /* در انتهای صفحه، در بالای صفحه باقی می‌ماند */
    left: 112%; /* در انتهای صفحه، در سمت راست صفحه باقی می‌ماند */
    opacity: 1;
  }
  31%{
    top: 2%; /* در انتهای صفحه، در بالای صفحه باقی می‌ماند */
    left: 112%; /* در انتهای صفحه، در سمت راست صفحه باقی می‌ماند */
    opacity: 0;
  }
  35%{
    opacity: 0;
    top: 18%; 
    left: 0; 
  }
  36%{
    opacity: 1;
  }
  66%{
    opacity: 1;
    top: 18%; 
    left: 112%; 
  }
  67%{
    top: 18%; 
    left: 112%; 
    opacity: 0;
  }
  70%{
    opacity: 0;
    top: 34%; 
    left: 0; 
  }
  71%{
    opacity: 1;
  }
 
  100%{
    top: 34%; 
    left: 110%;
  }
}
@keyframes movePathTwo {
  from{
    margin-left: 8%;
  }
  to{
    margin-left: -100%;
  }
  // 0%{
  //   margin-left: 5%;
  // }
  // 30%{
  //   margin-left: -20%;
  // }
}