.Slogan{
  position: fixed;
  direction: ltr;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  /* background-color: rgb(239, 238, 238); */
  margin-right: 0;
  top: 0;
  display: flex;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
  color: #00c846;
}

.Hiden{
  display: none;
}
.NotAnime{
  color: #00c846 ;
  position: absolute;
  top: 50%;
  margin-left: 13%;
  transform: translateX(-13%);
  font-weight: bold;
  font-size: 25px;
  
}
.TextShow{
  /* width: 60%; */
  margin-left: 25vh;
  transform: translateX(-50%);
  /* margin-right: auto; */
  font-weight: bold;
  font-size: 24px;
  font-family: 'Vazirmatn', sans-serif;
  animation-name: fadeIn;
  animation-duration: 1s;
  /* animation-fill-mode: forwards; */
  animation-timing-function: ease-in-out;
}
.BackSlogan{
  position: fixed;
  direction: ltr;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  /* background-color: rgb(239, 238, 238); */
  margin-right: 0;
  top: 0;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
  color: #00c846;
  display: none;
  animation-name: fadeOutBackSlogan;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;

}
.BackText{
  opacity: 0;
  margin-left: 25vh;
  transform: translateX(-50%);
  /* margin-right: auto; */
  font-weight: bold;
  font-size: 25px;
  font-family: 'Vazirmatn', sans-serif;
  animation-name: fadeOutBack;
  animation-duration: 1s;
  /* animation-fill-mode: forwards; */
  animation-timing-function: ease-in-out;
}
.SloganWitoutAnime{
  position: fixed;
  direction: ltr;
  width: 50vw; /* نصف عرض صفحه */
  height: 100vh;
  /* background-color: rgb(239, 238, 238); */
  margin-right: 0;
  top: 0;
  display: flex;
  justify-content: center; /* ترتیب افقی در وسط */
  align-items: center; /* ترتیب عمودی در وسط */
  color: #00c846;
}
.TextShowWitoutAnime{
    /* width: 60%; */
    margin-left: 25vh;
    transform: translateX(-50%);
    /* margin-right: auto; */
    font-weight: bold;
    font-size: 25px;
    font-family: 'Vazirmatn', sans-serif;

}
@keyframes fadeIn {
  0%{
    opacity: 0;
    
    transform: translate(-50%,+70px) ;
  }
  30%{
    opacity: 0;
    transform: translate(-50%,+70px) ;

  }
  100% {
    transform: translate(-50%,0px) ;
    opacity: 1;
  }
}
@keyframes fadeOutBack {
  0%{
    opacity: 1;
    transform: translate(-50%,0px) ;
  }
  30%{
    opacity: 1;
    transform: translate(-50%,0px) ;

  }
  100% {
    opacity: 0;
    transform: translate(-50%,+70px) ;
  }
}
@keyframes fadeOutBackSlogan {
  0%{
    display: flex;
  }
  90%{
    display: flex;



  }
  100% {
    display: flex;


  }
}
@media screen and (min-width:1920px) {
  .Slogan{
    color: #00c846 ;
    position: absolute;
    top: 50%;
    margin-left: 18%;
    transform: translateX(-18%);
    font-weight: bold;
    font-size: 30px;
    font-family: 'Vazirmatn', sans-serif;
  }
  .NotAnime{
    color: #00c846 ;
    position: absolute;
    top: 50%;
    margin-left: 18%;
    transform: translateX(-18%);
    font-weight: bold;
    font-size: 25px;
  }

}