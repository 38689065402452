@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800&display=swap');

html {
  font-size: 16px; /* مقدار پایه برای تعیین 1em */
}
body {
  margin: 0;
  padding: 0;
  background: black;
  BOX-SIZING: BORDER-BOX;
  font-family: "Vazirmatn";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 0em;
}

body::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::selection{
  background-color: #00c846;
  color: white;
}