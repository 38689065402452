.PageFore {
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
  /* width: 70vw; */
  }
  .Cloum {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* width: 50vw; */
    
  }
  .H1 {
    font-weight: bold;
    margin-bottom: 30px;
  }
  .Pragragh {
    margin-bottom: 30px;
  }
  .H2 {
    font-weight: bold;
    margin-bottom: 30px;
  }
  .H2Special {
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .PragraghSpecial {
    margin-bottom: 8px;
  }
  