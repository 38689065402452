.LineOne{
    position: relative;
    animation-duration: 3s;
    animation-name: lineOne;
    animation-timing-function: ease;
}
.LineTwo{
    position: relative;
    animation-duration: 3s;
    animation-name: lineTwo;
    animation-timing-function: ease;
}
.JustShow{
  animation-duration: 2s;
  animation-name: justShow;
  animation-timing-function: ease;
}
@keyframes lineOne{
    0% {
        opacity: 0;
        // transform: translateY(-70px);
      }
      60%{
        opacity: 0;
        transform: translateY(+180px);
      }
      100% {
        opacity: 1;
        transform: translateY(-50);
      }
}
@keyframes lineTwo{
    0% {
        opacity: 0;
        transform: translateY(700px);
      }
      80%{
        opacity: 0;
        transform: translateY(+1800px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
}
@keyframes justShow {
  0% {
    opacity: 0;
   
  }
  50%{
    opacity: 0.5;
  
  }
  100% {
    opacity: 1;
  
  }
}