.Pragragh{
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 10%;
    overflow: auto;
}

.btn{
   display: flex ;
   flex-direction: column  ;
   align-items: end;
}
.h3{
   
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-top:5%;
}
.Body{  
  font-size: 18px;
  flex-grow: 100;
  color: #000000;
  // margin-top: 1rem;
  background-color: white;
  overflow-y: auto;
  scroll-padding-left: -15%;
  border-radius: 10px;
  overflow-x: hidden;
  }
.Body::-webkit-scrollbar {
    width: 0.4em;
    // position: absolute;
    // left: 5%;
    // background-color: #00c846;
  }
  .Body::-webkit-scrollbar-thumb {
    background: #00c846;
    border-radius: 25px;
    padding-top: 2%;
  }
  .Body::-webkit-scrollbar-track {
    // background: rgb(177, 53, 53);
    border-radius: 25px;
  }
  .Body::-webkit-scrollbar-button {
    background: rgba(177, 53, 53, 0);
    // border-radius: 25px;
    height: 18px;
  }