.menu-container,
.menu-container *,
.menu-items-container,
.menu-items-container * {
  box-sizing: border-box;
}

.menu-container {
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 40px;
}

.menu-container .line {
  height: 30px;
  width: 30px;
  position: absolute;
  left: 4px;
  top: 4px;
  transition: transform 300ms ease-in-out;
}

.menu-container .line-inner {
  height: 4px;
  width: 32px;
  background: #222;
  border-radius: 4px;
  position: absolute;
  transition: transform 200ms ease-in-out;
  transition-delay: 200ms;
}

.menu-container .line-inner-1 {
  top: 12px;
  transform: translateY(-5px);
}

.menu-container .line-inner-2 {
  bottom: 12px;
  transform: translateY(5px);
}

.menu-container.active .line-1 {
  transform: rotateZ(45deg);
}

.menu-container.active .line-2 {
  transform: rotateZ(-45deg);
}

.menu-container.active .line-inner {
  transform: none;
  transition: transform 200ms ease-in-out;
}

.menu-container.active .line {
  transition-delay: 200ms;
}

.menu-items-container {
  background: #111;
  position: absolute;
  top: 80px;
  right: 40px;
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: all 700ms cubic-bezier(0.13, 0.86, 0.11, 1.19);
  transition-delay: 100ms;
}

.menu-items-container.active {
  opacity: 1;
  transform: scale(1);
}

.menu-items-container a {
  color: #fff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 12px 40px;
  display: inline-block;
  text-transform: uppercase;
  border: 1px solid #313131;
  width: 100%;
  font-size: 14px;
  transition: all 200ms ease;
}

.menu-items-container a:hover {
  background: #222;
}

.menu-items-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
}