  .verticalListLi {
    transform: rotate(270deg);
    font-weight: 500;
    font-size: 1.13rem;
    // display: flex;
    margin: 0 5rem 0 5rem;
    cursor: pointer;
  }
  .verticalListLi:hover {
    color: #ffffff;
  }
 
  .VerticalList>li{
    /* position: relative; */
    margin-top: 65px;
    transform: rotate(270deg);
    font-weight: 600;
    font-size: 18px;
    display: flex;
    line-height: 1.7;
  }
  .Logo {
    cursor: pointer;
    /* text-align: center; */
    transform: rotate(270deg);
    padding: 10%;
    /* font-weight: bold; */
    /* margin-bottom: 86px; */
    // margin-top: 18vh;
    margin-bottom: 55px;
    margin-top: 15%;
    /* position: relative; */
    
  }
  
  .ShowHeader {
    position: fixed;
    // position: relative;
    background-color: #00c846;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 8rem;
    height: 100vh;
    bottom: 0%;
    top: 0%;
    margin-left: 60px;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 50;
    visibility: visible;
    // margin: auto 0 ;
    // box-sizing: border-box;
    // height: 100%;
    // bottom: 0%;
    // // max-height: 1024px;
    // padding-top: 0;
    // padding-bottom: 0;
    // background-color: #00c846;
  }
  /* .ShowHeaderMenuHamburger {
    position: fixed;
    background-color: #00c846;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    top: 0%;
    overflow: hidden;
    z-index: 50;
    visibility: visible;
  } */
  .NotShow {
    position: absolute;
    z-index: -15;
  }
  .NotShowLogo{
    display: none;
  }
  .ActiveMneu {
  
    color: #ffffff;
    border-top: 3px solid rgb(244, 242, 242);
    border-bottom: 3px solid rgb(244, 242, 242);
  }
  .Monogram {
    /* position: relative; */
    /* right: -3px; */
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-top:20px;
    cursor: pointer;
    /* width: 10px; */
    /* height: 110px; */
  }
  .ImgMobile{
    background-image: url("../../assets/JPG/diyaris-picture-s.jpg");
  }
  @keyframes HeaderActive {
    from {
      height: 36%;
    }
  
    to {
      height: 100%;
    }
  }
  @keyframes NotMid {
    from {
      bottom: 0%;
    }
  
    to {
      bottom: -5%;
    }
  }
  .line-1 {
    height: 1px;
    background: black;
  }
.Anime{
  // animation: name duration timing-function delay iteration-count direction fill-mode;
  position: relative;
  animation-duration: 2s;
  animation-name: Move;
  animation-timing-function: ease;
  animation-iteration-count:infinite ;
}
// @keyframes Move{
//   0%{
//     left: -15%;
//   }
//   100%{
//     left: 100%;
//   }
// }
//   @media screen and (min-width:1920px) {
//     .VerticalList > li {
//       /* right: 5%; */
//       /* margin-right: 15px; */
//       position: relative;
//       margin-top: 50%;
//       transform: rotate(270deg);
//       font-weight: 600;
//       font-size: 18px;
//       display: flex;
//       line-height: 1.7;
  
//     }
//     .VerticalList {
//       width: 8vw;
//       display: flex;
//       flex-direction: column;
//       /* position: fixed; */
//       margin-top: 50%;
//       text-align: center;
//       align-items: center;
//       margin-right:-12%;
//       cursor: pointer;
//       justify-content: center;
//       flex-wrap: nowrap;
//     }
//     .Logo {
//       cursor: pointer;
//       text-align: center;
//       transform: rotate(270deg);
//       font-weight: bold;
//       margin-bottom: 86px;
//       margin-top: 128px;
//       position: fixed;
//       left: 8%;
//       top: 75%;
//     }
//   }