.Menu {
  /* background-color: rgba(10, 10, 10, 0.313); */
}
.DetailsCustomer {
  width: 75%;
  font-size: 18px;
  flex-grow: 100;
  color: #000000;
  position: relative;
  top: 2rem;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-padding-left: -15%;
  border-radius: 10px;
  .Texts{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
  }
}
.DetailsCustomer::-webkit-scrollbar {
  width: 0.4em;
  // position: absolute;
  // left: 5%;
  // background-color: #00c846;
}
.DetailsCustomer::-webkit-scrollbar-thumb {
  background: #00c846;
  border-radius: 25px;
  padding-top: 2%;
}
.DetailsCustomer::-webkit-scrollbar-track {
  // background: rgb(177, 53, 53);
  border-radius: 25px;
}
.DetailsCustomer::-webkit-scrollbar-button {
  background: rgba(177, 53, 53, 0);
  // border-radius: 25px;
  height: 18px;
}
.Conainers {
  /* width: 70%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0%;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* will-change: ; */
  /* margin-left: auto;
  margin-right: auto; */
}
.SmallContainer {
  border: 2px solid rgb(0, 0, 0);
  margin-right: 5px;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
}
.explanation {
  margin-top: 20px;
}
.explanation > h2 {
  // margin-bottom: 20px;
}
.ContainerDiyarisAndAbii {
  margin-top: 30px;
  margin-bottom: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/* .Diyaric {
 
  width: 25vw;
  border: 2px solid #00c846;
  height: 80vh;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.DiyaricSmallCountainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24.8vw;
  height: 60px;
  top: 35px;
  background-color: #00c846;
  direction: ltr;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
} */
.Abiii {
  width: 400px;
  border: 2px solid #0050ff;
  height: 500px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.AbiiiSmallCountainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 398px;
  height: 60px;
  top: 400px;
  background-color: #0050ff;
  direction: ltr;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.DiyaricPragraph {
  margin: 8px;
}
.AbiiiPragraph {
  margin: 8px;
}
.PragraphAbiiiSmallCountainer {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.LogoDiyaris {
  width: 50px;
}

.Pagination {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* // margin-top: -60%; */
    color: white;
    background-color: white;
    width: 10vh;
    margin-left: auto;
    margin-right: auto;
    height: 80%;

}
.PaginationMenu {
  display: flex;
  align-items: center;
  text-align: center;
  color: #00c846b1;
  padding: 1em;
  cursor: pointer;
  width: 20vw;
  align-content: center;
  justify-content: center;
}
.ActivePagination {
  font-weight: bold;
  border-bottom: 2px solid #00c846;
  border-top: 2px solid #00c846;
}
.NotActivePagination {
  cursor: pointer;
}
.active{
  display: block;
  width: 100%; 
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 50%;  
  mask-image: url("../../assets/Customers/Logo/JPG/green.jpg") ;
  mask-mode: alpha;
}
.Mask{
  // width: 227px;
  // height: 200px;
  // background: blue linear-gradient(red, blue);
   -webkit-mask-image: url("../../assets/Customers/Logo/JPG/green.jpg");
    // -webkit-mask-mode: alpha;
  mask-image: url("../../assets/Customers/Logo/JPG/green.jpg");
  mask-mode: alpha;
}
