.swiper-slide {
    max-width: fit-content;
    cursor: pointer;
    font-family: 'Vazirmatn';
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    opacity: .5;
    margin: auto 1rem;
    margin:0 15px;
  }
  
  
  .entext{
    /* font-family: 'Arial'!important; */
    font-size: 16px;
    font-weight: 800;
  }