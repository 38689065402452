.Home {
    //part 01 for img in background Home
    // background-image: url("../../assets/JPG/Diyaris\ -\ Website\ \(v2\)\ -\ Picture\ \(Desktop\)\ -\ Color.jpg");
    background-size: cover;
    object-fit: cover;
    background-position: center center;
    background-repeat: no-repeat;
    max-width: 100%;

    height: auto;
    overflow-y: hidden;
    background-color: black;
    //part 02 : img is not in the background
    height: 100vh;
    .ImageHome {
        object-fit: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        // flex-grow: 1;
    }
    .ImageHomeS{
        object-fit: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100vw;
        height: 100vh;
    }
    .Delay {
        opacity: 1;
        animation-duration: 2s;
        animation-name: ShowMenu;
        animation-timing-function: ease;
        overflow-y: hidden;
    }
    @keyframes ShowMenu {
        0%{
            opacity: 0;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}
