.BlackContainer {
  position: relative;
  background-color: black;
  width: 100vh;
  overflow-y: auto;
}

.Logos {
  margin-top: 2%;
  margin-left: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
 
}

.Logos > div {
  flex-basis: 10%;
}

.Text {
  color: white;
  position: fixed;
  left: 50%;
  top: 5%;
}
.SizeLogos{
  /* width: 130px; */
  /* height: 120px; */
  padding-left: 0px;
  margin-left: 20px;
  
}
.Action{
  margin-top: 20%;
  offset-path: path("M -50,20 L 600,20");
  animation: move 3000ms infinite  ease-in-out;
  /* width: 40px;
  height: 40px; */
}

/* @keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
} */
/* اضافه کردن استایل‌های مربوط به دسته‌بندی و انیمیشن‌ها */
/* در Style.module.css */
.Logos {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* تعداد دسته‌های ده‌تایی را مشخص کنید */
  gap: 20px; /* فاصله بین لوگوها و دسته‌ها */
}

.SizeLogos {
  width: 100%;
  max-width: 100px; /* تغییر اندازه لوگو به دلخواه */
  height: auto;
  transition: transform 0.3s ease-in-out; /* افزودن انیمیشن به تغییر حالت */
}

.Action:hover .SizeLogos {
  transform: scale(1.1); /* تغییر اندازه لوگو هنگام هاور روی آن */
}
.scene{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
}

.canvas{
  background: #000;
}