.PageOne {
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
  font-size: 21px;
}
.CloumOne {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    
}
.Pragragh{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CloumTwo{
  flex: 1;
  display: flex;
  flex-direction: column;
  width:90vw;
  margin-left: auto;
  margin-right: auto;
}
.Title{
  margin-top: 10px;
  margin-bottom: 10px;
}
.Sub{
  margin-top: 10px;
  margin-right: 5px;

}
.CloumThree{
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 90vw;
}
.ItemsTop{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.ItemsBottom{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}