.DetailsCustomer {
    width: 95%;
    font-size: 21px;
    flex-grow: 100;
    color: white;
    position: relative;
    top: 2rem;
    overflow-y: auto;
    scroll-padding-left: -15%;
    border-radius: 10px;
    .Texts{
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      width: 50vw;
    }
  }
  .Conainers {
    /* width: 70%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0%;
    align-items: start;
    text-align: right;
    justify-content: right;
    /* will-change: ; */
    /* margin-left: auto;
    margin-right: auto; */
  }
  .SmallContainer {
    border: 2px solid rgb(255, 255, 255);
    margin-right: 5px;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    text-align: right;
  }
  .explanation {
    margin-top: 20px;
  }
  .explanation > h2 {
    // margin-bottom: 20px;
  }
  .ContainerDiyarisAndAbii {
    margin-top: 30px;
    margin-bottom: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .Abiii {
    width: 400px;
    border: 2px solid #0050ff;
    height: 500px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .AbiiiSmallCountainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 398px;
    height: 60px;
    top: 400px;
    background-color: #0050ff;
    direction: ltr;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .DiyaricPragraph {
    margin: 8px;
  }
  .AbiiiPragraph {
    margin: 8px;
  }
  .PragraphAbiiiSmallCountainer {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .LogoDiyaris {
    width: 50px;
  }
  
  .Pagination {
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* // margin-top: -60%; */
      color: white;
      background-color: white;
      width: 10vh;
      margin-left: auto;
      margin-right: auto;
      height: 80%;
  
  }
  .PaginationMenu {
    display: flex;
    align-items: center;
    text-align: center;
    color: #00c846b1;
    padding: 1em;
    cursor: pointer;
    width: 20vw;
    align-content: center;
    justify-content: center;
  }
  .ActivePagination {
    font-weight: bold;
    border-bottom: 2px solid #00c846;
    border-top: 2px solid #00c846;
  }
  .NotActivePagination {
    cursor: pointer;
  }

  