.HomeMenu {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);

  .ContainerGreen {
    background-color: #00c846;
    align-items: center;
    width: 8rem;
    height: 38vh; /* استفاده از واحد vh برای ارتفاع */
    display: flex;
    flex-direction: column;
    /* با پیشوند برای Chrome و Safari */
    justify-content: -webkit-flex-end;
    justify-content: center;
    // position: relative;
    bottom: 0;
    /* left: 50%; */
    margin-left: 50%;
    transform: translateX(-50%); /* تغییر در ترجمه موقعیت افقی */
    opacity: 1;
    animation-duration: 2s;
    animation-name: SmallContainer;
    animation-timing-function: ease;
  }
  .BackContainerGreen {
    background-color: #00c846;
    align-items: center;
    width: 130px;
    height: 30%;
    display: flex;
    flex-direction: column;
    /* با پیشوند برای Chrome و Safari */
    justify-content: -webkit-flex-end;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 50%;
    /* left: 50%;*/
    transform: translate(-50%);
    opacity: 1;
    animation-duration: 2s;
    animation-name: BackSmallContainer;
    animation-timing-function: ease;
    z-index: 0;
  }
  .MenuHome {
    bottom: 0px;
    height: 100%;
    color: rgb(5, 5, 5);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: -130px;
    font-weight: 600;
    font-size: x-large;
    left: 0%;
    cursor: pointer;
  }
  .MenuHome>li :hover{
    color: #ffffff;
  }
  .Chinesh {
    position: relative;
    bottom: 12%;
    left: 10.5%;
    animation-duration: 3s;
    animation-name: ChineshAnime;
    animation-timing-function: ease;
  }
  .LogoAnimation {
    cursor: pointer;
    /* text-align: center; */
    transform: rotate(270deg);
    /* font-weight: bold; */
    margin-top: 445%;
    position: relative;
    left: 0%;
  }
  .MenuFrist1 {
    position: fixed;
    top: 2%;
    opacity: 1;
    animation-duration: 2.3s;
    animation-name: menufrist1;
    animation-timing-function: ease;
  }
  .MenuFrist1:hover {
    color: #ffffff;
  }
  .MenuFrist2 {
    position: fixed;
    top: 15%;
    opacity: 1;
    animation-duration: 2.8s;
    animation-name: menufrist2;
    animation-timing-function: ease;
  }

  .MenuFrist2:hover {
    color: #ffffff;
  }
  .MenuFrist3 {
    position: fixed;
    top: 32%;
    opacity: 1;
    animation-duration: 3.2s;
    animation-name: menufrist3;
    animation-timing-function: ease;
  }
  .MenuFrist3:hover {
    color: #ffffff;
  }
  .MenuFrist4 {
    position: fixed;
    top: 45%;
    opacity: 1;
    animation-duration: 3.5s;
    animation-name: menufrist4;
    animation-timing-function: ease;
  }
  .MenuFrist4:hover {
    color: #ffffff;
  }
  .WihoutAnimeMenuFrist1{
    position: fixed;
    top: 2%;
    opacity: 1;
    animation-duration: 2s;
    animation-name: justShowMenu;
    animation-timing-function: ease;
  }
  .WihoutAnimeMenuFrist1:hover {
    color: #ffffff;
  }
  .WihoutAnimeMenuFrist2{
    position: fixed;
    top: 15%;
    opacity: 1;
    animation-duration: 2s;
    animation-name: justShowMenu;
    animation-timing-function: ease;
  }
  .WihoutAnimeMenuFrist2:hover {
    color: #ffffff;
  }
  .WihoutAnimeMenuFrist3{
    position: fixed;
    top: 32%;
    opacity: 1;
    animation-duration: 2s;
    animation-name: justShowMenu;
    animation-timing-function: ease;
  }
  .WihoutAnimeMenuFrist3:hover {
    color: #ffffff;
  }
  .WihoutAnimeMenuFrist4{
    position: fixed;
    top: 45%;
    opacity: 1;
    animation-duration: 2s;
    animation-name: justShowMenu;
    animation-timing-function: ease;
  }
  .WihoutAnimeMenuFrist4:hover {
    color: #ffffff;
  }
  .Hiden {
    display: none;
  }

  .Logo {
    text-orientation: mixed;
    text-align: center;
    transform: rotate(270deg);
    font-weight: bold;
    margin-bottom: 86px;
    margin-top: 120px;
    animation-duration: 2s;
    animation-name: LogoChinesh;
  }
  .Up {
    position: fixed;
    top: -5%;
    z-index: 50;
  }
  .GoUp {
    // position: fixed;
    // // margin-bottom: 45%;
    // animation-duration: 2s;
    // animation-name: goup;
    // animation-timing-function: ease;
  }
  .NotGoUp {
    animation-duration: 2s;
    animation-name: ShowLogo;
    animation-timing-function: ease;
  }
  .ShowWihoutAnime{
    background-color: #00c846;
    align-items: center;
    width: 8rem;
    height: 38vh;
    display: flex;
    flex-direction: column;
    justify-content: -webkit-flex-end;
    justify-content: center;
    bottom: 0;
    margin-left: 50%;
    transform: translateX(-50%); /* تغییر در ترجمه موقعیت افقی */
    opacity: 1;
  }
  .ShowMenuWithoutAnime{

  }
  .NoneTextHomePage {
    opacity: 0;
    position: fixed;
    font-size: xx-large;
    left: 10.5%;
    animation-duration: 3s;
    animation-name: texthomemenu;
  }

  .verticalListLi:hover {
    color: #ffffff;
  }
  .MenuFristHide {
    opacity: 0;
    margin-top: 2px;
    animation-duration: 2s;
    animation-name: menufristhide;
    animation-timing-function: linear;
  }
  .NotShowLogo {
    display: none;
  }
  @keyframes ChineshAnime {
    0% {
      opacity: 0;
      animation-delay: 2s;
    }
    50% {
      opacity: 0;
    }
    83% {
      opacity: 0;
    }
    90% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes MenuHomeAnimtion {
    from {
      height: 0%;
    }

    to {
      height: 100%;
    }
  }
  @keyframes SmallContainer {
    0% {
      height: -20%;
      opacity: 0;
      margin-bottom: -38vh;
    }
    50% {
      height: -20%;
      opacity: 0;
      margin-bottom: -38vh;
    }
    100% {
      opacity: 1;
      height: 38vh;
      // margin-bottom: 38vh;
    }
  }
  @keyframes BackSmallContainer {
    0% {
      height: 100%;
      opacity: 1;
    }
    65% {
      height: 100%;
      opacity: 1;
    }
    100% {
      height: 30%;
    }
  }
  @keyframes goup {
    0% {
      // position: absolute;
      top: -440%;
      // margin-bottom: 40%;
    }
    40% {
      // position: absolute;
      top: -10%;
    }
    50% {
      // position: absolute;
      top: -10%;
    }
    100% {
      // position: absolute;
      // top: -440%;
      // position: absolute;
    top: -10%;
    }
  }
  @keyframes menufrist {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes menufrist1 {
    0% {
      opacity: 0;
      top: -8%;
    }

    50% {
      opacity: 0;
      top: -8%;
    }
    65% {
      opacity: 0;
      top: -8%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 2%;
    }
  }
  @keyframes menufrist2 {
    0% {
      opacity: 0;
      top: 2%;
    }
    50% {
      opacity: 0;
      top: 2%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 15%;
    }
  }
  @keyframes menufrist3 {
    0% {
      opacity: 0;
      top: 15%;
    }
    50% {
      opacity: 0;
      top: 15%;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 32%;
    }
  }
  @keyframes menufrist4 {
    0% {
      opacity: 0;
      top: 25%;
    }

    50% {
      opacity: 0;
      top: 25%;
    }
    /* 75% {
      opacity: 0.5;
    } */
    100% {
      opacity: 1;
      top: 45%;
    }
  }
  @keyframes menufristhide {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes ShowLogo {
    0%{
      opacity: 0;
    }
    50%{
      opacity: 0.5;
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes justShowMenu {
    0%{
      opacity: 0;
    }
    50%{
      opacity: 0.5;
    }
    100%{
      opacity: 1;
    }
  }
}
