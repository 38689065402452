.Form {
    padding-top: 20px;
}

.Body {
    font-size: 21px;
    width: 100%;
    // flex-grow: 100;
    // color: white;
    // overflow-y: auto;
    // scroll-padding-left: -15%;
    // border-radius: 10px;
    // position: relative;
    // top: 10rem;
}

