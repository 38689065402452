/* .Pragragh {
  color: black;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 0%;
  margin-bottom: 10%;
  overflow: auto;
} */
.Form {
  padding-top: 20px;
}

.Body{
 
  font-size: 18px;
  flex-grow: 100;
  color: #000000;
  // margin-top: 1rem;
  background-color: white;
  overflow-y: auto;
  scroll-padding-left: -15%;
  border-radius: 10px;
}
.Body::-webkit-scrollbar {
  width: 0.4em;
  /* // position: absolute;
  // left: 5%;
  // background-color: #00c846; */
}
.Body::-webkit-scrollbar-thumb {
  background: #00c846;
  border-radius: 25px;
  padding-top: 2%;
}
.Body::-webkit-scrollbar-track {
  // background: rgb(177, 53, 53);
  border-radius: 25px;
}
.Body::-webkit-scrollbar-button {
  background: rgba(177, 53, 53, 0);
  // border-radius: 25px;
  height: 18px;
}