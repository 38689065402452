.Loding {
  width: 100%;
  height: 100vh;
  background-color: #00c846;
  opacity: 1;
  /* animation-name: Loding;
  animation-timing-function: linear;
  animation-duration: 2.8s;  */
  /* animation-iteration-count: infinite; */
}
.LodingNotAnime{
    width: 100%;
    height: 100vh;
    background-color: #00c846;
}
.Logo {
  width: 180px;
  height: 260px;
  position: fixed;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.CircleOuter {
  /* position: absolute; */
  animation-name: LogoCircleOuter;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.CircleInner {
  position: absolute;
  width: 160px;
  left: 10px;
  top: 10px;
  animation-name: LogoCircleInner;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.DSign {
  width: 90px;
  position: absolute;
  left: 55px;
  top: 55px;
  animation-name: LogoDSign;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes Loding {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    80%{
      opacity: 1;
    }
    95%{
      opacity: 0.5;
    }
    100%{
        opacity: 0;
    }
}
@keyframes LogoCircleOuter {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  85%{
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes LogoCircleInner {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60%{
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  85%{
    opacity: 0;
  }

  /* 70%{
      opacity: 0;
    } */
  100% {
    opacity: 0;
  }
}
@keyframes LogoDSign {
  0% {
    opacity: 0;
  }30%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  /* 60% {
    opacity: 0.5;
  } */
  75% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
