.Logo {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 28rem; */
  /* position: relative; */
  /* bottom: 50rem; */
  /* left: 0; */
  /* width: 100%; */
  /* height: calc(100% - 40%); */
  z-index: 3;
 
}
.LogoHome {
  width: 180px;
  height: 260px;
  /* position: fixed; */
  /* position: relative ; */
  color: #ffffff;
  /* margin-left: auto;
  margin-right: auto; */
  /* top: auto;
  bottom: auto; */
  /* margin-bottom: auto; */
  /* top: 180%; */
  /* left: 50%; */
  /* padding-bottom: 0.1rem; */
  /* transform: translateY(-50%); */
  /* margin-top: 50%; */
  margin-left: 50%;
  transform: translateX(-50%);
  /* margin-right: 35%; */
  margin-bottom: 80%;
  animation-duration: 2s;
  animation-name: LogoHomeAnimation;
  animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
}
.CircleInner {
  position: absolute;
  animation-duration: 2s;
  animation-name: circleinner;
  animation-timing-function: linear;
}
.NotAnimeCircleInner {
  position: absolute;
}
.NotShowLogo {
  display: none;
}
.CircleOuter {
  position: absolute;
  animation-duration: 2s;
  animation-name: circleouter;
  animation-timing-function: linear;
}
.NotAnimeCircleOuter {
  position: absolute;
}
.Sign {
  width: 50px;
  position: absolute;
  left: 68px;
  /* top: 120%; */
  padding-top: 63px;
  animation-duration: 1.5s;
  animation-name: signanime;
  animation-timing-function: linear;
}
.GateLogo {
  position: absolute;
  animation-duration: 2s;
  animation-name: gate;
  animation-timing-function: ease;
}
.LineUp {
  position: absolute;
  animation-duration: 1s;
  animation-name: lineup;
  animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
}
.Typography {
  position: absolute;
  /* top: 95%; */
  /* top: -115%; */
  animation-duration: 1s;
  animation-name: typography;
  animation-timing-function: ease;
}
.LineDown {
  position: absolute;
  animation-duration: 1s;
  animation-name: linedown;
  animation-timing-function: ease;
}

.AnimeLogoHome {
  position: fixed;
  cursor: pointer;
  top: -1%;
  left: 50%;
  width: 155px;
  height: 100px;
  transform: translateX(-50%);
  animation: sign 1s linear;
}
.BackLogoHome {
  width: 180px;
  height: 260px;
  position: fixed;
  color: #ffffff;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: BackLogo 3s ease;
}
.Pragraph {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  top: 60vh;
  font-size: 24px;
  width: 277px;
  text-align: center;
  padding: 5px;
}

.PragraphFirstLine {
  margin-top: 0px;
  animation-duration: 2s;
  animation-name: PragraphFirstLineAnimtion;
  animation-timing-function: cubic-bezier(0.58, 0.01, 0.37, 1);
}
.PragraphTwoLine {
  margin-top: -3.5rem;
  animation-duration: 2s;
  animation-name: PragraphTwoLineAnimtion;
  animation-timing-function: cubic-bezier(0.58, 0.01, 0.37, 1);
}
.AnimeLogoHiden {
  animation-duration: 2s;
  animation-name: TypographyAnime;
  opacity: 0;
  display: none;
}
.ShowWihoutAnime{
  opacity: 1;
  position: absolute;
}
.AnimePragraphHiden {
  animation-duration: 2s;
  animation-name: TypographyAnime;
  opacity: 0;
  display: none;
}
.DownSign {
  position: relative;
  top: 0px;
  left: 0%;
}
.UpSign {
  width: 50px;
  position: absolute;
  left: 58px;
  top: 53px;
  animation-duration: 1s;
  animation-name: AnimationSign;
  animation-timing-function: linear;
}

.BackSign {
  width: 48px;
  position: absolute;
  left: 70px;
  /* top: 120%; */
  padding-top: 63px;
  animation-duration: 3s;
  animation-name: BackSignAnime;
  animation-timing-function: linear;
}
.NotUpSign {
  width: 50px;
  position: absolute;
  left: 68px;
  top: 65px;

}
.UpCircleOuter {
  position: absolute;
  animation-duration: 1s;
  animation-name: AnimationUpCircleOuter;
  animation-timing-function: linear;
}
.BackCircleOuter {
  position: absolute;
}
.BackCircleInner {
  position: absolute;
}
.NotAnimeLogoHome {
  width: 180px;
  height: 260px;
  color: #ffffff;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 80%;
}
.BackGate {
  position: absolute;
  animation-duration: 3s;
  animation-name: BackGate;
  animation-timing-function: ease;
}
.BackPragraph {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  top: 60%;
  font-size: 24px;
  width: 277px;
  text-align: center;
  /* animation-duration: 2s;
  animation-name: BackGate;
  animation-timing-function: ease; */
}
@keyframes sign {
  0% {
    top: 250px;
    left: 50%;
  }

  25% {
    top: 200px;
    left: 50%;
  }
  50% {
    top: 150px;
    left: 50%;
  }
  75% {
    top: 100px;
    left: 50%;
  }
  100% {
    top: 5px;
    left: 50%;
  }
}

@keyframes downsign {
  0% {
    top: -200px;
    left: 0%;
    animation-delay: 4s;
  }
  25% {
    top: -150px;
    left: 0%;
    animation-delay: 4s;
  }
  50% {
    top: -100px;
    left: 0%;
    animation-delay: 4s;
  }
  75% {
    top: -50px;
    left: 0%;
  }
  100% {
    top: 0px;
    left: 0%;
  }
}
@keyframes TypographyAnime {
  0% {
    opacity: 1;
    animation-delay: 3s;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes LogoSee {
  0% {
    opacity: 0;
    animation-delay: 2s;
  }

  50% {
    opacity: 0.5;
    animation-delay: 2s;
  }

  100% {
    opacity: 1;
  }
}

@keyframes signanime {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  40% {
    opacity: 0;
    transform: scale(0, 0);
  }
  45% {
    opacity: 0.5;
    transform: scale(0.1, 0.1);
  }
  70% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes circleouter {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  40% {
    opacity: 0;
    transform: scale(0, 0);
  }
  45% {
    opacity: 0.5;
    transform: scale(0.1, 0.1);
  }
  65% {
    opacity: 1;
    transform: scale(1, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes circleinner {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  40% {
    opacity: 0;
    transform: scale(0, 0);
  }
  45% {
    opacity: 0.5;
    transform: scale(0.1, 0.1);
  }
  60% {
    opacity: 1;
    transform: scale(1, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes gate {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  /* 35%{
    opacity: 0.5;
  } */
  43% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lineup {
  0% {
    /* margin-top:50%; */
    opacity: 0;
    left: 80%;
  }
  5% {
    /* margin-top:50%; */

    left: 80%;
  }
  30% {
    /* margin-top:50%; */
    opacity: 0.5;
  }
  to {
    /* margin-top:50%; */
    opacity: 1;
    left: 0;
  }
}

@keyframes typography {
  from {
    opacity: 0;
    left: -70%;
  }

  to {
    opacity: 1;
    left: 0;
  }
}
@keyframes linedown {
  from {
    opacity: 0;
    left: 80%;
  }
  10% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes PragraphFirstLineAnimtion {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  50% {
    opacity: 0;
    margin-top: 50px;
  }
  65% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes PragraphTwoLineAnimtion {
  0% {
    margin-top: 0px;
    opacity: 0;
  }
  50% {
    margin-top: -30px;
    opacity: 0;
  }
  65% {
    margin-top: -30px;
    opacity: 0;
  }
  80% {
    opacity: 0;
    margin-top: -30px;
  }
  100% {
    margin-top: -55px;
    opacity: 1;
  }
}

@keyframes AnimationSign {
  0% {
    margin-top: 0px;
    opacity: 1;
  }
  20% {
    margin-top: -35px;
    opacity: 1;
  }
  50% {
    margin-top: -35px;
    opacity: 1;
  }
  80% {
    margin-top: -35px;
    opacity: 1;
  }
  /* 85%{
    margin-top: -30px;
  } */
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes AnimationUpCircleOuter {
  0% {
    margin-top: 0px;
    opacity: 1;
  }

  50% {
    margin-top: -10px;
    opacity: 1;
  }
  100% {
    margin-top: -10px;
    opacity: 1;
  }
}
@keyframes LogoHomeAnimation {
  0% {
    margin-bottom: 150%;
  }
  50% {
    margin-bottom: 150%;
  }
  100% {
    margin-bottom: 80%;
  }
}
@keyframes BackLogo {
  0% {
    width: 155px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: -1%;
  }
  66% {
    width: 155px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: -1%;
  }
  100% {
    top: 45%;
  }
}
@keyframes BackSignAnime {
  0% {
    width: 50px;
    left: 58px;
    top: -10px;
  }
  65% {
    width: 50px;
    left: 58px;
    top: -10px;
  }
  99% {
    width: 50px;
    left: 58px;
    /* top: -10px; */
  }
  100% {
    left: 58px;
    top: -10px;
    width: 50px;
  }
}
@keyframes BackGate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media screen and (min-height: 1080px) {
  .LogoHome {
    width: 180px;
    height: 260px;
    position: fixed;
    color: #f7f2f2;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-duration: 2s;
    animation-name: LogoHomeAnimation;
    animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
  }
  .Pragraph {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    top: 62%;
    font-size: 24px;
    width: 277px;
    text-align: center;
    line-height: 1.7;
  }

  @keyframes LogoHomeAnimation {
    0% {
      top: 70%;
    }
    50% {
      top: 70%;
    }
    100% {
      top: 52%;
    }
  }
}


@media screen and (1020px<=width<= 1300px) {
  .LogoHome {
    width: 180px;
    height: 260px;
    position: fixed;
    color: #f7f2f2;
    top:33%;
    left: 50%;
    transform: translateX(-50%);
    animation-duration: 2s;
    animation-name: LogoHomeAnimation;
    animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
  }
  .Pragraph {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    top: 60vh;
    font-size: 24px;
    width: 277px;
    text-align: center;
    line-height: 1.7;
  }

  @keyframes LogoHomeAnimation {
    0% {
      top: 70%;
    }
    50% {
      top: 70%;
    }
    100% {
      top: 80vw;
    }
  }
}
@media screen and (380px<=width<= 433px) {
  .LogoHome {
    width: 180px;
    height: 260px;
    position: fixed;
    color: #f7f2f2;
    top:49%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation-duration: 2s;
    animation-name: LogoHomeAnimation;
    animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
  }
  .Pragraph {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    top: 60vh;
    font-size: 24px;
    width: 277px;
    text-align: center;
    line-height: 1.7;
  }

  @keyframes LogoHomeAnimation {
    0% {
      top: 70%;
    }
    50% {
      top: 70%;
    }
    100% {
      top: 80vw;
    }
  }
}
@media screen and (360px<=width< 380px) {
  .LogoHome {
    width: 180px;
    height: 260px;
    position: fixed;
    color: #f7f2f2;
    top:48%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation-duration: 2s;
    animation-name: LogoHomeAnimation;
    animation-timing-function: cubic-bezier(0.7, 0, 0.24, 1);
  }
  .Pragraph {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    top: 60vh;
    font-size: 24px;
    width: 277px;
    text-align: center;
    line-height: 1.7;
  }

  @keyframes LogoHomeAnimation {
    0% {
      top: 70%;
    }
    50% {
      top: 70%;
    }
    100% {
      top: 80vw;
    }
  }
} */