.Pragragh{
    /* position: fixed; */
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 10%;
  overflow: auto;
}

.btn{
    // margin-top: 5%;
   display: flex ;
   flex-direction: column  ;
   align-items: end;
}
.h3{
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-top:5%;
}
.Body{
  font-size: 21px;
  flex-grow: 100;
  color: white;
  overflow-y: auto;
  scroll-padding-left: -15%;
  border-radius: 10px;
  position: relative;
  top: 8rem;
  }
