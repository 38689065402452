.Pragragh{
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-top:0%;
    margin-bottom: 10%;
    overflow: auto;
}
.Form{
    
    padding-top: 20px;
}
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  
  .title{
   
    color: white;
    margin-left: 20%;
    margin-right: 20%;
    margin-top:5%;
  }
  .Body{
    font-size: 21px;
    flex-grow: 100;
    color: white;
    overflow-y: auto;
    scroll-padding-left: -15%;
    border-radius: 10px;
    position: relative;
    top: 8rem;
  }